import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { graphql } from "gatsby";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    wrapper: {
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    modalContentWrapper: {
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    modalContent: {
      backgroundColor: "white",
      margin: "0 auto",
      borderRadius: "1rem",
      padding: "5%",
      textAlign: "center",
    },
    btn: {
      margin: "0.5rem",
    },
  });
});

const BuildPage = ({ data }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const content = JSON.parse(data.allStoryblokEntry.edges[0].node.content);
  const url = content.build_webhook;

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const publish = () => {
    fetch(url, {
      method: "POST",
    }).then(() => setOpen(false));
  };

  return (
    <Container className={classes.wrapper}>
      <Typography variant="h4" style={{ marginBottom: "24px" }}>
        Make sure you publish your changes before you go live!
      </Typography>
      <Button variant="contained" color="secondary" onClick={handleOpen}>
        GO LIVE TO PRODUCTION!
      </Button>
      <Modal open={open} onClose={handleClose}>
        <div className={classes.modalContentWrapper}>
          <div className={classes.modalContent}>
            <Typography variant="h5" style={{ marginBottom: "24px" }}>
              Are you sure you want to go live with your recent changes? Real
              customers will see them.
            </Typography>
            <div>
              <Button className={classes.btn} onClick={publish}>
                Confirm
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.btn}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </Container>
  );
};

export default BuildPage;

export const query = graphql`
  query BuildQuery {
    allStoryblokEntry(filter: { slug: { in: ["build"] } }) {
      edges {
        node {
          content
          name
          slug
        }
      }
    }
  }
`;
